import React from "react";
import { globalData } from "@/context/rootContext";
import { mainOfRegion, isJP, isSK, isSea, isAfkjourney, isTW } from "@/common/utils/envData";
import salaryStyle from "@/components/Dialog/RewardsExplained/index.module.scss";
import tierStyle from "@/components/Dialog/TiersExplained/index.module.scss";
import warpath from "@/assets/images/warpath_logo.png";
import dislyte from "@/assets/images/dislyte_logo.png";
import afk from "@/assets/images/afk.png";
import igame_en from "@/assets/images/afk2/igame_en.png";
import igame_jp from "@/assets/images/afk2/igame_jp.png";
import igame_kr from "@/assets/images/afk2/igame_kr.png";
import igame_tw from "@/assets/images/afk2/igame_tw.png";
import igame_vi from "@/assets/images/afk2/igame_vi.png";
// import igame_zh from "@/assets/images/afk2/igame_zh-ch.png";
import aoc from "@/assets/images/aoc.png";
import rok from "@/assets/images/rok.png";
import COD from "@/assets/images/samo_logo.png";
import farlight84 from "@/assets/images/farlight84.png";

import DislyteTierTable from "@/components/Dialog/TiersExplained/dislyteTable";
import IGameTable from "@/components/Dialog/RewardsExplained/IGameRewardTable/iGameRewardTable";

// discord链接
const discordLink = {
  dislyte: "https://discord.gg/d4uR3F6bUa",
  warpath: "https://discord.gg/uRKdbZukku",
  COD: "https://discord.gg/KNzDdjwTHk",
  farlight84: "https://discord.gg/9xPvwkrVSm",
  rok: "https://discord.gg/7tDSxUeEQJ",
  igame: "https://discord.gg/5kNt9eDgN4"
};

// 游戏icon
export const gameIcons = {
  warpath,
  dislyte,
  afk,
  igame: (!mainOfRegion && igame_en) || (isAfkjourney && igame_en) || (isSea && igame_vi) || (isJP && igame_jp) || (isSK && igame_kr) || (isTW && igame_tw) || igame_en,
  aoc,
  rok,
  COD,
  farlight84
};

// 游戏名称
const gameTag = {
  igame: "igame"
};


const DislyteExplained = () => (
  <div className={salaryStyle.desc}>
    <p>The Dislyte TV Program features two types of reward systems applicable to creators from all three tiers. All creators will be eligible to receive rewards depending on the corresponding level which their creations fall into. Your level will be determined by video views and given that all Dislyte-related content made is 100% the Creator&apos;s own original work. </p>
    <p className={salaryStyle.t1}>Monthly Total View-based Rewards </p>
    <p>Monthly total view-based rewards take all Dislyte-related videos published in a certain month into account. Creators are awarded rewards based on different levels set for various amounts of video views. When a Creator reaches a certain level for 3 consecutive months, the Creator will qualify for an opportunity to sign a long-term contract with Farlight Games</p>
    <p className={salaryStyle.t1}>Task View-based Rewards</p>
    <p>Task view-based rewards are based on one exact video with specific task requirements for the task which will be set by the Staff. The exact reward differs per level and will be determined according to your task&apos;s performance.</p>
  </div>
);

const WarpathExplained = () => (
  <div className={salaryStyle.desc}>
    <p className={salaryStyle.t1}>In-game and montary benefits will be distributed through the following channels.</p>
    <p>1. In-game rewards: Directly mailed through in-game.</p>
    <p>2. Cash: Bank transfer to your provided bank account.</p>
    <p>3. CDK: Currently CDKs are not offered as rewards to Creators.</p>
  </div>
);

const CODExplained = () => (
  <div className={salaryStyle.desc}>
    <p>Once you are above the Apprentice tier, you are eligible for monetary compensation based on your tier and performance. Each tier has its own range of monetary benefits. Once all formal processes are done, monetary benefits will be distributed through a bank transfer to your provided bank account.</p>
  </div>
);

const Farlight84Explained = () => (
  <div className={salaryStyle.desc}>
    <p>1. All views are calculated and based on natural and organic views shown on your channel. (Multiple platforms will be calculated separately)</p>
    <p>2. View tools (tools that increase views) are prohibited; we encourage free and natural creation. (If view tools are detected, you might not be able to get the reward according to the standard. )</p>
    <p>3. For FCC who reaches Cash Reward tier, you must have a valid bank account to proceed with the reward sending. (Currently only bank transfer is supported)</p>
    <p>4. Calculating will be done and rewards be sent within the first 10 days of next month. (for example, Rewards for March will be issued between April 1st to 10th); Cash rewards within 15 days varying on different banks.</p>
    <p>5. The standard for long videos(streams) and short videos will be calculated separately.</p>
  </div>
);

const ROKExplained = () => (
  <div className={salaryStyle.desc}>
    <p>In-game and montary benefits will be distributed through the following channels.</p>
    <p>&nbsp;</p>
    <p>1. In-game rewards: Directly mailed through in-game or sent through discord with a giftcode.</p>
    <p>2. Cash: Bank transfer to your provided bank account.</p>
  </div>
);

const IGameExplainedData = {
  en: (
    <div className={salaryStyle.desc}>
      <p>Find more details in the #reward-system channel on our creator discord server</p>
    </div>
  ),
  id: (
    <div className={salaryStyle.desc}>
      <p>Program Kreator Konten Video AFK Journey memiliki dua jenis sistem hadiah yang berlaku untuk para kreator dari ketiga tier. Semua kreator berhak menerima hadiah berdasarkan level mereka. Level-mu akan ditentukan oleh jumlah penayangan video dan jika semua konten yang berhubungan dengan AFK Journey yang dibuat adalah 100% karya asli Kreator. </p>
      <p>Hadiah Berbasis Tayangan Total Bulanan: Hadiah berbasis tayangan total bulanan akan memperhitungkan semua video tentang AFK Journey yang diterbitkan dalam bulan tertentu. Kreator akan diberi hadiah berdasarkan level yang berbeda untuk jumlah tayangan video yang beragam. Saat Kreator mencapai level tertentu selama 3 bulan berturut-turut, maka dirinya akan berhak untuk dapat peluang untuk terikat dalam kontrak jangka panjang dengan Farlight Games.</p>
      <p>Hadiah Berbasis Tayangan Tugas</p>
      <p>Hadiah berbasis tayangan tugas didasarkan pada satu video yang memenuhi persyaratan tugas tertentu untuk tugas yang ditetapkan oleh Staf. Hadiah berbeda untuk setiap level dan akan ditentukan berdasarkan kinerja tugasmu.</p>
      <IGameTable />
    </div>
  ),
  th: (
    <div className={salaryStyle.desc}>
      <p>โปรแกรมวิดีโอคอนเทนต์ครีเอเตอร์ของ AFK Journey จะมีระบบการให้รางวัลสองประเภทที่เหมาะสมกับครีเอเตอร์จากทั้งสามระดับ ครีเอเตอร์ทุกคนจะมีสิทธิ์ได้รับรางวัลตามระดับที่สอดคล้องกันที่ซึ่งผลงานถูกจัดอยู่ตามระดับนั้นๆ ระดับของท่านจะถูกพิจารณาตามยอมรับชมวิดีโอและพิสูจน์แล้วว่าคอนเทนต์ที่เกี่ยวข้องกับ AFK Journey ทั้งหมดนั้นเป็นผลงานต้นฉบับของครีเอเตอร์เอง 100% </p>
      <p>รางวัลตามยอดชมรายเดือน รางวัลตามยอดชมรายเดือนจะพิจารณาจากวิดีโอที่เกี่ยวข้องกับ AFK Journey ที่เผยแพร่ในเดือนที่กำหนด ครีเอเตอร์จะได้รับรางวัลตามระดับต่างๆ ที่กำหนดไว้ตามจำนวนรับชมของวิดีโอต่างๆ เมื่อครีเอเตอร์ไปถึงระดับที่กำหนดไว้เป็นเวลา 3 เดือนติดต่อกัน ครีเอเตอร์จะมีคุณสมบัติได้รับโอกาสเซ็นสัญญาระยะยาวกับ Farlight Games</p>
      <p>รางวัลตามยอดชมงาน</p>
      <p>รางวัลตามยอดชมงานจะขึ้นอยู่กับหนึ่งวิดีโอที่มีความต้องการงานเฉพาะสำหรับงานที่กำหนดโดยพนักงาน รางวัลที่แน่นอนจะแตกต่างกันไปตามระดับและจะถูกพิจารณาตามประสิทธิภาพของงานท่าน</p>
      <IGameTable />
    </div>
  ),
  vi: (
    <div className={salaryStyle.desc}>
      <p>Chương Trình Nhà Sáng Tạo Nội Dung Video của AFK Journey có hai loại hệ thống phần thưởng áp dụng cho nhà sáng tạo từ cả ba bậc. Tất cả nhà sáng tạo đều đủ điều kiện nhận phần thưởng tùy thuộc theo bậc tương ứng với tác phẩm của họ. Bậc của bạn sẽ được xác định dựa trên lượt xem video và với điều kiện là tất cả nội dung liên quan đến AFK Journey 100% là tác phẩm gốc của chính Nhà Sáng Tạo. </p>
      <p>Tổng Phần Thưởng Dựa Trên Lượt Xem Hằng Tháng Tổng phần thưởng dựa trên lượt xem hàng tháng sẽ tính dựa trên tất cả video liên quan đến AFK Journey được đăng tải trong một tháng nhất định. Nhà Sáng Tạo sẽ nhận phần thưởng dựa trên các cấp độ khác nhau tương ứng với số lượt xem video khác nhau. Nhà Sáng Tạo đạt đến cấp độ nhất định trong 3 tháng liên tục sẽ đủ điều kiện xem xét cơ hội ký hợp đồng dài hạn với Farlight Games.</p>
      <p>Phần Thưởng dựa trên Lượt Xem Nhiệm Vụ</p>
      <p>Phần thưởng dựa trên lượt xem nhiệm vụ được tính bằng một video đáp ứng các yêu cầu nhiệm vụ cụ thể mà Nhân Viên đặt ra. Phần thưởng chính xác sẽ khác nhau tùy theo cấp độ và được xác định dựa trên thành tích nhiệm vụ của bạn.</p>
      <IGameTable />
    </div>
  ),
  jp: (
    <div className={salaryStyle.desc}>
      <p>『AFKジャーニー』動画コンテンツクリエイタープログラムでは、3つのティアに属するクリエイター全員に適用される2種類の報酬システムを設けています。すべてのクリエイターは、それぞれのレベルに応じた報酬を受け取ることができます。レベルは動画再生回数によって決定され、『AFKジャーニー』に関連するすべてのコンテンツが100%クリエイターのオリジナル作品であることが条件とされます。</p>
      <p>月間総再生回数に基づく報酬</p>
      <p>月間総再生回数に基づく報酬では、特定の月に公開された『AFKジャーニー』関連のすべての動画が対象となります。クリエイターは、動画再生回数によって設定されたレベルに応じて報酬を獲得できます。クリエイターが3カ月連続で一定のレベルに達すると、Farlight Gamesと長期契約を結ぶ機会が与えられます。</p>
      <p>再生回数タスクに基づく報酬</p>
      <p>再生回数タスクに基づく報酬は、スタッフによって設定される具体的なタスク要件を備えた特定の1つの動画に基づいています。報酬の具体的な内容はレベルごとに異なり、タスクの完了状況に応じて決定されます。</p>
      <IGameTable />
    </div>
  ),
  sk: (
    <div className={salaryStyle.desc}>
      <p>「AFK: 새로운 여정」 크리에이터 플랜에는 두 가지 보상 시스템이 있으며, 세 등급의 크리에이터에게 적용됩니다. 모든 크리에이터는 창작 관련 등급에 따라 보상을 획득할 수 있습니다. 등급은 영상 조회 수에 따라 결정되며, 「AFK: 새로운 여정」 관련 작품은 크리에이터의 100% 순수 창작물이어야 합니다. </p>
      <p>월간 조회 수 보상 </p>
      <p>월간 조회 수에 따른 보상은 한 달 동안 공개된 모든 「AFK: 새로운 여정」 관련 콘텐츠의 조회 수 등급에 따라 보상을 획득합니다. 크리에이터가 연속 3개월 특정 등급을 달성하면 크리에이터는 Farlight Games과 장기 계약을 체결할 수 있습니다. </p>
      <p>작업 보상 </p>
      <p>작업 보상은 작업자가 설정한 특정 작업 조건에 따라 주제에 부합하는 콘텐츠를 제작하는 것을 토대로 합니다. 실제 보상은 등급, 업무 수행 등 요소에 따라 변동됩니다.</p>
      <IGameTable />
    </div>
  ),
  "zh-tw": (
    <div className={salaryStyle.desc}>
      <p>《劍與遠征：啟程》影片創作者計畫包含兩種獎勵系統，適用於所有等級的創作者。所有創作者均可根據創作級別獲得對應獎勵。等級視影片觀看次數而定，且所有《劍與遠征：啟程》相關內容均為100％原創。</p>
      <p>月觀看數獎勵</p>
      <p>此獎勵將根據某個月內上傳過的《劍與遠征：啟程》相關影片。創作者根據觀看次數的級距獲得獎勵。當創作者連續三個月均達到某個級距時，創作者將有機會與Farlight Games簽訂長期合作合約。</p>
      <p>任務獎勵</p>
      <p>影片內需包含工作人員給予的特定條件。獎勵因級別而有所變動，並後續將會根據你的任務表現而定。</p>
      <IGameTable />
    </div>
  ),
};

const IGameExplained = () => {
  return IGameExplainedData[globalData.lang] || IGameExplainedData["en"];
};

// 星级描述 
const DislyteTierExplained = () => (
  <div className={tierStyle.desc}>
    <p className={tierStyle.t1}>Tier Perks Explained</p>
    <p>Apprentice Tier:</p>
    <p>- Invitation to our exclusive video content creator Discord Server</p>
    <p>- Exclusive ticket system on the Creator Discord server to receive 1 on 1 help from the staff</p>
    <p>- Access to official art assets to help you create your content</p>
    <p>- The possibility to get in-game rewards and/or monetary compensation</p>

    <p>Associate Tier:</p>
    <p>- All perks in Apprentice Tier</p>
    <p>- The possibility to have your content featured on our official social media platforms</p>

    <p>Partner Tier:</p>
    <p>- All Perks in previous tiers</p>
    <p>- Customized gift codes used for giveaways to your viewers</p>
    <p>- The possibility to get invited to official Dislyte events</p>
    <p>- The possibility of co-creating content with the Dislyte Team</p>


    <p className={tierStyle.t1}>How do we determine tiers and how can a Content Creator advance in tiers</p>
    <p>Apprentice:</p>
    <p>- All Creators will start in the Apprentice Tier.</p>
    <p>- Creators can advance to the Associate tier after publishing more than 10 Dislyte-related videos per month for 3 consecutive months. </p>

    <p>Associate:</p>
    <p>- Creators can advance to the Partner tier after publishing more than 10 Dislyte-related videos per month for 3 consecutive months after joining the Associate tier. </p>
    <p>- Creators who published less than 10 videos in the previous month will be demoted back to the Apprentice Tier.</p>

    <p>Partner:</p>
    <p>- Creators who have successfully met previous requirements from the Apprentice and Associate tiers will get promoted to the Partner Tier. </p>
    <p>- Creators who produced less than 10 videos in the current month will get demoted back to the Associate tier.</p>

    <p>*Creators who haven’t posted any content for 2 consecutive months will be temporarily removed from the program. </p>

    <p className={tierStyle.t1}>Rewards Explained</p>
    <p>The Dislyte TV Program features two types of reward systems applicable to creators from all three tiers. All creators will be eligible to receive rewards depending on the corresponding level which their creations fall into. Your level will be determined by video views and given that all Dislyte-related content made is 100% the Creator&apos;s own original work. </p>
    <p>Monthly Total View-based Rewards </p>
    <p>Monthly total view-based rewards take all Dislyte-related videos published in a certain month into account. Creators are awarded rewards based on different levels set for various amounts of video views. When a Creator reaches a certain level for 3 consecutive months, the Creator will qualify for an opportunity to sign a long-term contract with Farlight Games</p>
    <p>Task View-based Rewards</p>
    <p>Task view-based rewards are based on one exact video with specific task requirements for the task which will be set by the Staff. The exact reward differs per level and will be determined according to your task&apos;s performance.</p>

    <DislyteTierTable />
  </div>
);

const WarpathTierExplained = () => (
  <div className={tierStyle.desc}>
    <p className={tierStyle.t1}>How do we determine tiers?</p>
    <p>Tiers are mainly determined by the number of subscribers, content quality, community appreciation, and relevance to the game. All Creators will be reviewed and assigned after being thoroughly reviewed by the Staff.</p>

    <p className={tierStyle.t1}>What perks/advantages are related to each tier?</p>
    <p>All tiers have access to the Creators Discord, Staff Help, and Art assets. Associate Creators receive additional early sneak peeks and content promotion. Partnered Creators receive all perks mentioned before, and invitations to official events/content and monetary compensations.</p>

    <p className={tierStyle.t1}>How can Creators rank up in Tier?</p>
    <p>Creators can work on their subscribers, content quality, community appreciation, and content relevance to improve their chances to rank up a tier. The Staff will periodically review content creators to see if they are eligible for a promotion.</p>
  </div>
);

// COD-英文
const CODTierExplained = () => (
  <div className={tierStyle.desc}>
    <p className={tierStyle.t1}>Summary:</p>
    <p>As a content creator, you will receive small in-game rewards, have the potential to have your content featured on our channels and in-game, access to the Creator Discord Channel, exclusive sneak peeks, early update previews, and monetary compensation. </p>
    <p className={tierStyle.t1}>Apprentice:</p>
    <p>As an Apprentice content creator, you will receive:</p>
    <p>Access to the Creator Discord Channel</p>
    <p>Access to art assets</p>
    <p>Official support</p>
    <p>The possibility of receiving small in-game rewards or monetary compensation for your contributions</p>
    <p className={tierStyle.t1}>Associate:</p>
    <p>In the Associate tier, you will receive:</p>
    <p>All of the benefits of the Apprentice tier</p>
    <p>Exclusive sneak peeks</p>
    <p>Monetary compensation</p>
    <p>Official promotion of your channel</p>
    <p className={tierStyle.t1}>Partner:</p>
    <p>As a Partner content creator, you will receive:</p>
    <p>All of the benefits of the previous tiers</p>
    <p>Early update previews</p>
    <p>The potential for your content to be featured on our channels and in-game</p>
    <p>Monetary compensation for your contributions</p>
  </div>
);

const Farlight84TierExplained = () => (
  <div className={tierStyle.desc}>
    <p className={tierStyle.t2}>Farlight 84 Content Creator Tiers and Their Requirements and Perks</p>
    <p className={tierStyle.t1}>Level 1: Farlight 84 Rising Creators (FRC)</p>
    <p className={tierStyle.t1}>Requirement:</p>
    <p>- Create at least one Farlight 84-related video on a social media platform (Facebook, YouTube, Twitch, TikTok).</p>
    <p className={tierStyle.t1}>Perks:</p>
    <p>- Earn the FRC role on the Creator Zone Discord Server.</p>
    <p>- Get a chance to win Diamond rewards through FRC Tasks, including a Monthly Cash Reward Event.</p>
    <p>- Gain access to the Farlight 84 arts and assets media pack.</p>
    <p>- Connect and share experiences with fellow FRC members.</p>
    <p>&nbsp;</p>
    <p className={tierStyle.t1}>Level 2: Farlight 84 Content Creators (FCC)</p>
    <p className={tierStyle.t1}>Requirement:</p>
    <p>- Have more than 2,000 subscribers/followers on a social media platform.</p>
    <p>- Create at least one Farlight 84-related content piece.</p>
    <p className={tierStyle.t1}>Perks:</p>
    <p>- Attain the FCC role on the Creator Zone Discord Server.</p>
    <p>- Win Diamond rewards through FCC Tasks, including a Monthly Cash Reward Event.</p>
    <p>- Access the Farlight 84 arts and assets media pack.</p>
    <p>- Receive an exclusive FCC in-game Avatar Frame.</p>
    <p>- Gain early access to the Farlight 84 Vanguard Test Server APK.</p>
    <p>- Earn monthly rewards based on your views.</p>
    <p>- Have a chance to be promoted by official channels.</p>
    <p>&nbsp;</p>
    <p className={tierStyle.t1}>Level 3: Farlight 84 Star Creators (FSC)</p>
    <p className={tierStyle.t1}>Requirement:</p>
    <p>- Option A: 100,000+ subscribers.</p>
    <p>- Option B: 30,000+ subscribers + 150,000 Organic Monthly Views (Creators in this category must apply manually).</p>
    <p>- Produce at least 1 Farlight 84-related video.</p>
    <p className={tierStyle.t1}>Perks:</p>
    <p>- Receive an in-game Avatar Frame (Star CC).</p>
    <p>- Get 2 gift pack codes per month (gold coins999, diamonds20).</p>
    <p>- Gain access to the Star CC exclusive profile background.</p>
    <p>- Participate in 3rd-party Tournaments (Diamonds + Room cards).</p>
    <p>- Enjoy early access to exclusive new update assets.</p>
    <p>&nbsp;</p>
    <p className={tierStyle.t1}>Advanced Star Creators</p>
    <p className={tierStyle.t1}>Requirement:</p>
    <p>- Have 500,000+ subscribers.</p>
    <p>- Produce at least 1 Farlight 84-related video.</p>
    <p className={tierStyle.t1}>Perks:</p>
    <p>- Receive an additional 5,000 diamond subsidies per month.</p>
    <p>- Enjoy all the rights and benefits of Star CC Basic.</p>
    <p>&nbsp;</p>
    <p className={tierStyle.t1}>Super Star Creators</p>
    <p className={tierStyle.t1}>Requirement:</p>
    <p>- Have 1 million+ subscribers.</p>
    <p>- Produce at least 1 Farlight 84-related video.</p>
    <p className={tierStyle.t1}>Perks:</p>
    <p>- Receive an exclusive sticker (coming soon).</p>
    <p>- Get 3 gift pack codes per month (gold coins999, diamonds20).</p>
    <p>- Follow and interact with official media.</p>
    <p>- Be given priority for official cash cooperation as a contact candidate.</p>
    <p>- Enjoy all the rights and benefits of Star CC Advanced.</p>
    <p>&nbsp;</p>
    <p className={tierStyle.t1}>Additional Contract Perks for Super Star CC</p>
    <p>(For super star CC who have performed well in cooperation with the official or have been signed by the official for a long time):</p>
    <p>- Receive exclusive avatar frames and backgrounds.</p>
    <p>- Gain monthly cash cooperation opportunities.</p>
  </div>
);

const ROKTierExplained = () => (
  <div className={tierStyle.desc}>
    <p className={tierStyle.t1}>How do we determine tiers?</p>
    <p>We take into account several factors when determining tiers for our content creators, including metrics like average views in recent periods, video complete rate, active subscriber count, editing skills, knowledge, presentation abilities, community reputation, professionalism, and cooperation and helpfulness on ROK projects, etc. </p>
    <p className={tierStyle.t1}>Apprentice Tier</p>
    <p>- Verified Contributor Role on RoK official Discord server</p>
    <p>- Access to an exclusive Discord channel for promoting your content</p>
    <p>- Access to official RoK art assets</p>
    <p>- Entry eligibility for the RoK Annual Creator Award, with the chance to win exclusive RoK souvenirs</p>
    <p>- Opportunities to receive in-game rewards</p>
    <p className={tierStyle.t1}>Associate Tier</p>
    <p>- All benefits of the Apprentice tier</p>
    <p>- Potential to be featured on official RoK social media channels under Creator Spotlights </p>
    <p>- Dedicated 1-on-1 support and prioritized feedback to game developers</p>
    <p>- Collaboration opportunities with the RoK Team to co-create content</p>
    <p>- Chances to receive in-game rewards and/or monetary compensation</p>
    <p className={tierStyle.t1}>Partner Tier</p>
    <p>- All benefits of the previous tiers</p>
    <p>- Exclusive previews of upcoming updates</p>
    <p>- Monetary compensation tailored to your impact and contributions</p>
    <p>- Priority to participating in special events including Osiris League Official Livestreams, player meetups, and offline events for content creators.</p>
  </div>
);

const IGameTierExplainData = {
  en: (
    <div className={tierStyle.desc}>
      {/* <p className={tierStyle.t1}>Tier Perks Explained</p>
      <p>Apprentice:</p>
      <p>- Invitation to our exclusive video content creator Discord Server</p>
      <p>- Exclusive ticket system on the Creator Discord server to receive 1 on 1 help from the staff</p>
      <p>- Access to official art assets to help you create your content</p>
      <p>- The possibility to get in-game rewards and/or monetary compensation</p>
      <p className={tierStyle.t1}>Associate:</p>
      <p>- All perks in Apprentice Tier</p>
      <p>- The possibility to have your content featured on our official social media platforms</p>
      <p className={tierStyle.t1}>Partner:</p>
      <p>- All Perks in previous tiers</p>
      <p>- Customized gift codes used for giveaways to your viewers</p>
      <p>- The possibility to get invited to official AFK Journey events</p>
      <p>- The possibility of co-creating content with the AFK Journey Team</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p> */}
      <p className={tierStyle.t1}>How do we determine tiers and how can a Content Creator advance in tiers</p>
      <p className={tierStyle.t1}>Apprentice:</p>
      <p>- All Creators will start in the Apprentice Tier.</p>
      <p>- Creators can advance to the Associate tier after publishing more than 10 AFK Journey-related videos per month for 3 consecutive months. </p>
      <p className={tierStyle.t1}>Associate:</p>
      <p>- Creators can advance to the Partner tier after publishing more than 10 AFK Journey-related videos per month, accumulating a monthly total of over 30k views for 3 consecutive months after joining the Associate tier.</p>
      <p>- Creators who published less than 10 videos in the previous month will be demoted back to the Apprentice Tier.</p>
      <p className={tierStyle.t1}>Partner:</p>
      <p>- Creators who have successfully met previous requirements from the Apprentice and Associate tiers will get promoted to the Partner Tier. </p>
      <p>- Creators who produced less than 10 videos in the current month will get demoted back to the Associate tier.</p>
      <p>*Creators who haven’t posted any content for 2 consecutive months will be temporarily removed from the program. </p>
    </div>
  ),
  id: (
    <div className={tierStyle.desc}>
      {/* <p className={tierStyle.t1}>Penjelasan Keuntungan Tier</p>
      <p className={tierStyle.t1}>Apprentice:</p>
      <p>- Undangan ke Server Discord kreator konten video eksklusif kami</p>
      <p>- Sistem tiket eksklusif di server Discord Kreator untuk mendapatkan bantuan langsung dari staf</p>
      <p>- Akses ke aset art resmi untuk membantumu membuat konten- Peluang untuk mendapatkan hadiah dalam game dan/atau kompensasi uang</p>
      <p className={tierStyle.t1}>Associate:</p>
      <p>- Semua keuntungan di Tier Apprentice</p>
      <p>- Kemungkinan untuk menampilkan kontenmu di platform media sosial resmi kami </p>
      <p className={tierStyle.t1}> platformsPartner:</p>
      <p>- Semua Keuntungan di tingkat sebelumnya- Kode hadiah khusus yang digunakan untuk hadiah kepada audiens-mu</p>
      <p>- Peluang untuk diundang ke acara resmi AFK Journey</p>
      <p>- Peluang untuk membuat konten bersama dengan Tim AFK Journey</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p> */}
      <p className={tierStyle.t1}>Bagaimana kami menentukan tier dan bagaimana seorang kreator konten dapat naik tier</p>
      <p className={tierStyle.t1}>Apprentice:</p>
      <p>- Semua Kreator akan memulai dari Tier Apprentice.</p>
      <p>- Kreator dapat naik ke tier Associate setelah mempublikasikan lebih dari 10 video mengenai AFK Journey per bulan selama 3 bulan berturut-turut. </p>
      <p className={tierStyle.t1}>Associate:</p>
      <p>- Kreator dapat naik ke tier Partner setelah mempublikasikan lebih dari 10 video mengenai AFK Journey setiap bulannya, dengan mengumpulkan lebih dari total 30 ribu penayangan selama 3 bulan berturut-turut setelah masuk ke tier Associate.</p>
      <p>- Kreator yang mempublikasikan kurang dari 10 video di bulan sebelumnya akan turun ke Tier Apprentice.</p>
      <p className={tierStyle.t1}>Partner:</p>
      <p>- Kreator yang telah berhasil memenuhi persyaratan sebelumnya dari tier Apprentice dan Associate akan dipromosikan ke tier Partner.</p>
      <p>- Kreator yang menghasilkan kurang dari 10 video di bulan ini akan turun kembali ke tier Associate.</p>
      <p>*Kreator yang tidak memposting konten apa pun selama 2 bulan berturut-turut akan dihapus sementara dari program ini.</p>
    </div>
  ),
  th: (
    <div className={tierStyle.desc}>
      {/* <p className={tierStyle.t1}>คำอธิบายระดับของผลประโยชน์</p>
      <p className={tierStyle.t1}>ฝึกหัด:</p>
      <p>- คำเชิญสู่เซิร์ฟเวอร์ Discord ของวิดีโอคอนเทนต์ครีเอเตอร์เอ็กซ์คลูซีฟ</p>
      <p>- ระบบตั๋วเอ็กซ์คลูซีฟบนเซิร์ฟเวอร์ Discord ของครีเอเตอร์เพื่อรับความช่วยเหลือจากพนักงานแบบ 1 ต่อ 1</p>
      <p>- เข้าถึงแอสเซ็ตงานศิลป์ทางการเพื่อช่วยเหลือท่านในการสร้างคอนเทนต์ มีโอกาสที่จะได้รับรางวัลในเกมและ/หรือการชดเชยด้วยเงิน</p>
      <p className={tierStyle.t1}>มีส่วนร่วม:</p>
      <p>- ผลประโยชน์ทั้งหมดในระดับฝึกหัด</p>
      <p>- มีโอกาสที่คอนเทนต์ของท่านจะถูกนำเสนอบนแพลตฟอร์มโซเชียลมีเดียทางการของเรา </p>
      <p className={tierStyle.t1}>พันธมิตรแพลตฟอร์ม:</p>
      <p>- ผลประโยชน์ทั้งหมดในระดับก่อนหน้านี้ - รหัสของขวัญกำหนดเองสำหรับให้ท่านแจกแก่ผู้ชม</p>
      <p>- มีโอกาสได้รับเชิญเข้าสู่อีเวนต์ AFK Journey ทางการ</p>
      <p>- มีโอกาสร่วมสร้างคอนเมนต์กับทีมงาน AFK Journey</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p> */}
      <p className={tierStyle.t1}>วิธีพิจารณาระดับและวิธีเลื่อนระดับของคอนเทนต์ครีเอเตอร์</p>
      <p className={tierStyle.t1}>ฝึกหัด:</p>
      <p>- ครีเอเตอร์ทุกคนจะเริ่มในระดับฝึกหัด</p>
      <p>- ครีเอเตอร์สามารถเลื่อนไปถึงระดับที่สอดคล้องกันได้หลังจากเผยแพร่วิดีโอที่เกี่ยวข้องกับ AFK Journey 10 วิดีโอต่อเดือนเป็นเวลา 3 เดือนติดต่อกัน </p>
      <p className={tierStyle.t1}>มีส่วนร่วม:</p>
      <p>- ครีเอเตอร์สามารถเลื่อนไปยังระดับพันธมิตรได้หลังจากเผยแพร่วิดีโอที่เกี่ยวข้องกับ AFK Journey มากกว่า 10 วิดีโอต่อเดือน ยอดรับชมสะสมเกิน 30k ครั้งต่อเดือนเป็นเวลา 3 เดือนติดต่อกันหลังจากเข้าร่วมระดับมีส่วนร่วม</p>
      <p>- ครีเอเตอร์ที่เผยแพร่วิดีโอไม่ถึง 10 วิดีโอในเดือนก่อนหน้านี้จะถูกลดระดับกลับไปเป็นฝึกหัด</p>
      <p className={tierStyle.t1}>พันธมิตร:</p>
      <p>- ครีเอเตอร์ที่ผ่านความต้องการก่อนหน้านี้จากระดับฝึกหัดและมีส่วนร่วมได้สำเร็จจะเลื่อนขั้นไปเป็นระดับพันธมิตร</p>
      <p>- ครีเอเตอร์ที่ผลิตวิดีโอน้อยกว่า 10 วิดีโอในเดือนปัจจุบันจะถูดลดระดับกลับไปเป็นมีส่วนร่วม</p>
      <p>*ครีเอเตอร์ที่ไม่ได้โพสต์คอนเทนต์ใดเลยเป็นเวลาติดต่อกัน 2 เดือนจะถูกนำออกจากโปรแกรมชั่วคราว</p>
    </div>
  ),
  vi: (
    <div className={tierStyle.desc}>
      {/* <p className={tierStyle.t1}>Giải Thích về Quyền Lợi Theo Bậc</p>
      <p className={tierStyle.t1}>Tập Sự:</p>
      <p>- Lời mời tham gia máy chủ Discord dành cho nhà sáng tạo nội dung video độc quyền của chúng tôi</p>
      <p>- Hệ thống tư vấn độc quyền trên máy chủ Discord dành cho nhà sáng tạo, cung cấp hỗ trợ 1-1 từ nhân viên</p>
      <p>- Truy cập vào nội dung hình ảnh chính thức để thỏa sức sáng tạo nội dung của chính mình- Cơ hội nhận phần thưởng trong trò chơi và hoặc các khoản trả thưởng bằng tiền</p>
      <p className={tierStyle.t1}>Cộng Tác Viên:</p>
      <p>- Tất cả quyền lợi ở Bậc Tập Sự</p>
      <p>- Cơ hội hiển thị nội dung của bạn trên các nền tảng mảng xã hội chính thức của chúng tôi </p>
      <p className={tierStyle.t1}>Đối Tác Nền Tảng:</p>
      <p>- Toàn bộ quyền lợi ở các bậc trước- Mã quà tặng tùy chỉnh dùng để tổ chức sự kiện tặng quà cho người xem của bạn</p>
      <p>- Cơ hội nhận lời mời tham gia các sự kiện AFK Journey chính thức</p>
      <p>- Cơ hội hợp tác sáng tạo nội dung cùng Đội Ngũ AFK Journey</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p> */}
      <p className={tierStyle.t1}>Cách chúng tôi xác định bậc và cách giúp Nhà Sáng Tạo Nội Dung phát triển theo bậc</p>
      <p className={tierStyle.t1}>Tập Sự:</p>
      <p>- Tất cả Nhà Sáng Tạo sẽ bắt đầu ở Bậc Tập Sự.</p>
      <p>- Nhà Sáng Tạo có thể tiến lên bậc Cộng Tác sau khi đăng tải hơn 10 video liên quan đến AFK Journey/tháng, trong 3 tháng liên tiếp. </p>
      <p className={tierStyle.t1}>Cộng Tác Viên:</p>
      <p>- Nhà Sáng Tạo có thể tiến lên bậc Đối Tác sau khi đăng tải hơn 10 video liên quan đến AFK Journey/tháng, có tổng cộng hơn 30 nghìn lượt xem hằng tháng trong 3 tháng liên tiếp ở bậc Cộng Tác Viên.</p>
      <p>- Các Nhà Sáng Tạo đăng tải 10 video trở xuống trong tháng trước sẽ bị hạ xuống bậc Tập Sự.</p>
      <p className={tierStyle.t1}>Đối Tác:</p>
      <p>- Nhà Sáng Tạo đã đáp ứng thành công các yêu cầu trước đó từ bậc Tập Sự và Cộng Tác Viên sẽ được thăng lên bậc Đối Tác.</p>
      <p>- Nhà Sáng Tạo sản xuất 10 video trở xuống trong tháng hiện tại sẽ bị hạ bậc về Cộng Tác Viên.</p>
      <p>*Nhà Sáng Tạo không đăng tải bất kỳ nội dung nào trong 2 tháng liên tiếp sẽ tạm thời bị mời ra khỏi chương trình. </p>
    </div>
  ),
  jp: (
    <div className={tierStyle.desc}>
      <p className={`${tierStyle.t1} mask-title`}>ティアの決定方法とコンテンツクリエイターのティア昇格方法</p>
      <p className={tierStyle.t1}>見習い：</p>
      <p>- すべてのクリエイターは見習いティアからスタートします。</p>
      <p>- 月に10本以上の『AFKジャーニー』関連動画を3カ月連続で公開したクリエイターは、アソシエイトティアに昇格できます。</p>
      <p className={tierStyle.t1}>アソシエイト：</p>
      <p>- クリエイターがアソシエイトティアに参加後、月に10本以上の『AFKジャーニー』関連動画を3カ月連続で公開し、月間総再生回数が3万を超えると、パートナーティアに昇格できます。</p>
      <p>- 前月に公開した動画が10本未満のクリエイターは、見習いティアに降格します。</p>
      <p className={tierStyle.t1}>パートナー：</p>
      <p>- 見習いティアとアソシエイトティアでこれまでの要件を満たしたクリエイターは、パートナーティアに昇格します。</p>
      <p>- 当月に制作した動画が10本未満のクリエイターは、アソシエイトティアに降格します。</p>
      <p>※2カ月連続で何のコンテンツも投稿していないクリエイターは、一時的にプログラムから除外されます。 </p>
    </div>
  ),
  sk: (
    <div className={tierStyle.desc}>
      {/* <p className={tierStyle.t1}>등급 혜택 설명</p>
      <p className={tierStyle.t1}>[견습] </p>
      <p>크리에이터 디스코드 서버로 초대합니다. </p>
      <p>크리에이터 디스코드 서버에서 운영팀과 1대1로 소통할 수 있습니다. </p>
      <p>콘텐츠 제작을 위해 공식 아트 자산을 사용할 수 있습니다. </p>
      <p>게임 내 아이템 보상 또는 현금 보상을 획득할 수 있습니다. </p>
      <p className={tierStyle.t1}>[정식] </p>
      <p>모든 견습 단계의 혜택, 콘텐츠를 공식 소셜 미디어 플랫폼에 공개할 수 있습니다. </p>
      <p className={tierStyle.t1}>[파트너] </p>
      <p>이전의 모든 보상 포함, 팬들에게 맞춤형 기프트코드를 증정합니다. </p>
      <p>공식 「AFK: 새로운 여정」 이벤트에 초대받아 참여할 수 있습니다. </p>
      <p>「AFK: 새로운 여정」팀과 함께 콘텐츠를 제작할 수 있습니다</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p> */}
      <p className={tierStyle.t1}>등급 상승 설명</p>
      <p className={tierStyle.t1}>[견습] </p>
      <p>모든 크리에이터는 견습 단계부터 시작합니다. </p>
      <p>크리에이터가 연속 3개월 동안 매월 「AFK: 새로운 여정」 관련 영상을 10개 이상 공개하면 파트너 단계로 진급할 수 있습니다. </p>
      <p className={tierStyle.t1}>[정식] </p>
      <p>크리에이터는 정식 단계 진입 후 연속 3개월 동안 매월 「AFK: 새로운 여정」 관련 영상을 10개 이상 공개 및 매월 누적 조회 수 10,000회를 기록하면 정식 단계로 진급할 수 있습니다. </p>
      <p>지난달 10개 미만의 영상을 공개한 크리에이터는 견습 단계로 강등됩니다. </p>
      <p className={tierStyle.t1}>[ 파트너] </p>
      <p>견습 및 정식 조건을 충족한 크리에이터는 협력 파트너로 진급하게 됩니다. </p>
      <p>당월 10개 미만의 영상을 제작한 크리에이터는 정식 단계로 강등됩니다.</p>
      <p>*2개월 연속 콘텐츠를 공개하지 않은 크리에이터는 잠시 해당 플랜에서 제외됩니다.</p>
    </div>
  ),
  "zh-tw": (
    <div className={tierStyle.desc}>
      <p className={`${tierStyle.t1} mask-title`}>我們如何確定階層，創作者如何晉升階層</p>
      <p className={tierStyle.t1}>實習生：</p>
      <p>所有創作者將從實習生等級開始。</p>
      <p>連續三個月內，每月均上傳10個（含）以上有關《劍與遠征：啟程》的影片後，即可升至正式創作者等級。</p>
      <p className={tierStyle.t1}>實習生：</p>
      <p>正式：</p>
      <p>每月均上傳10個（含）以上有關《劍與遠征：啟程》的影片後，且每月累計超過30,000次觀看數，即可升至正式創作者等級。</p>
      <p>上個月上傳少於10個影片的創作者將降至實習生等級。</p>
      <p>合作夥伴：</p>
      <p>符合學徒和正式創作者等級門檻的創作者將晉升為合作夥伴。</p>
      <p>本月製作少於10個影片的創作者將被降至正式創作者。</p>
      <p>*連續兩個月沒有上傳任何內容的創作者將會被暫時移出計畫。</p>
    </div>
  )
};

// iGame
const IGameTierExplained = () => {
  return IGameTierExplainData[globalData.lang] || IGameTierExplainData["en"];
};

// 收益解释
const explainedList = {
  warpath: <WarpathExplained />,
  dislyte: <DislyteExplained />,
  COD: <CODExplained />,
  farlight84: <Farlight84Explained />,
  rok: <ROKExplained />,
  igame: <IGameExplained />
};

// 星级描述
const tierExplainedList = {
  warpath: <WarpathTierExplained />,
  dislyte: <DislyteTierExplained />,
  COD: <CODTierExplained />,
  farlight84: <Farlight84TierExplained />,
  rok: <ROKTierExplained />,
  igame: <IGameTierExplained />
};

// 收入提示
const earningTip = {
  dislyte: [
    "In-game and montary benefits will be distributed through the following channels.",
    "1. In-game rewards: Directly mailed through in-game.",
    "2. Cash: Bank transfer to your provided bank account.",
    "3. CDK: Currently CDKs are not offered as rewards to Creators."
  ],
  warpath: [
    "1. In-game rewards: Directly mailed through in-game",
    "*If creators have any questions on the rewards, ",
    "they can feel free to check with the mod in charge for further clarification",
    "2. Cash: International wires to bank accounts",
    "(i.e. Contracted signed in advance for legitimacy)",
    "3. CDK: Content Ops Team will be responsible for finalizing the list of qualified creators, ",
    "submitting the application to the devs team and aligning the gift codes with these qualfied creators"
  ],
  COD: [
    "How do we determine tiers?",
    "We take into account several factors when determining tiers for our content creators, including things ",
    "like average views in recent period, subscriber count, editing skills, knowledge, presentation abilities, ",
    "community likability, professionalism, and cooperation and helpfulness on Call of Dragons projects. ",
  ],
  farlight84: [
    "Reward Explanation",
    "<br />",
    "Submission Requirements",
    "<span>1. </span>Video works must remain public.",
    "<span>2. </span>The title must include the keyword: Farlight 84. Video descriptions need to include #farlight84 #fcc #farlight84fcc.",
    "<span>3. </span>Content must be related to the Farlight 84 game.",
    "<br />",
    "Weekly Reward Settlement Criteria",
    "<span>1. </span>Only creators who reach the FCC level are eligible for weekly diamond rewards based on their play counts. (Calculation is based on reaching the FCC standard level on settlement day; rewards will not be retroactively given for previous works.)",
    "<span>2. </span>Weekly video views count rewards are calculated separately for each platform.",
    "<span>3. </span>Weekly reward criteria (YouTube, Facebook, Twitch):",
    "<span> </span>Views reach 250, receive 150 diamonds.",
    "<span> </span>Views Reach 1,000, Receive 450 Diamonds.",
    "<span> </span>Views Reach 2,000, Receive 650 Diamonds.",
    "<span> </span>Views Reach 3,000, Receive 750 Diamonds.",
    "<span> </span>Views Reach 4,000, Receive 850 Diamonds.",
    "<span> </span>Views Reach 5,000, Receive 950 Diamonds.",
    "<span> </span>Views Reach 6,000, Receive 1,050 Diamonds.",
    "<span> </span>Views Reach 7,000, Receive 1,250 Diamonds.",
    "<span> </span>Views Reach 10,000, Receive 1,450 Diamonds.",
    "<span> </span>Views Reach 20,000, Receive 1,650 Diamonds.",
    "<span> </span>Views Reach 50,000, Receive 2,000 Diamonds.",
    "<span> </span>Views Reach 100,000, Receive 2,500 Diamonds.",
    "4. Weekly reward criteria (TikTok):",
    "<span> </span>Views reach 250, receive 100 diamonds.",
    "<span> </span>Views Reach 1,000, Receive 350 Diamonds.",
    "<span> </span>Views Reach 2,000, Receive 500 Diamonds.",
    "<span> </span>Views Reach 3,000, Receive 600 Diamonds.",
    "<span> </span>Views Reach 4,000, Receive 700 Diamonds.",
    "<span> </span>Views Reach 5,000, Receive 800 Diamonds.",
    "<span> </span>Views Reach 6,000, Receive 900 Diamonds.",
    "<span> </span>Views Reach 7,000, Receive 1,000 Diamonds.",
    "<span> </span>Views Reach 10,000, Receive 1,200 Diamonds.",
    "<span> </span>Views Reach 20,000, Receive 1,400 Diamonds.",
    "<span> </span>Views Reach 50,000, Receive 1,800 Diamonds.",
    "<span> </span>Views Reach 125,000, Receive 2,200 Diamonds.",
    "<span> </span>Weekly views data will be counted and settled twice. The first settlement is on the next Wednesday and rewards will be distributed on every Friday. The second settlement is counted and settled again four weeks later on Wednesday, ensuring the final views for each video are as accurate as possible.",
    "<span> </span>...",
    "<br />",
    "Note",
    "<span>1. </span>Data fetching might be delayed by 1-2 days. If complete data for the week is not available, it will be calculated in the subsequent four-week settlement's additional data.",
    "<span>2. </span>If the published videos for the current week are not submitted before the following Wednesday, they will be included in the four-week settlement's additional data. Creators should ensure timely submissions; missing the settlement deadline will result in no reward distribution.",
    "<span>3. </span>If your UID has not been verified, rewards will not be distributed. Once UID verification is completed, rewards will be distributed retroactively.",
    "<span>4. </span>Usage of any tools/methods for promotion or boosting is not allowed. Any violation will result in blacklisting and loss of creator status.",
    "<span> </span>...",
    "<br />",
    "Example",
    "Chance is a Farlight 84 creator with 2000 followers on both his YouTube and TikTok, making him an FCC. He has correctly verified his UID. Chance is eligible for weekly views count rewards.",
    "Settlement Period: Videos published between July 31st and August 6th.",
    "Settlement Date: August 9th.",
    "Chance - YouTube views: 5000, TikTok views: 8000. On August 11th, he can receive 950 + 1000 diamonds, totaling 1950 diamonds.",
    "Settlement Date: September 6th.",
    "Chance - YouTube views: 8000, TikTok views: 30000. On September 8th, he can receive 1250 + 1400 - 1950, totaling 700 diamonds.",
    "Therefore, Chance's videos published between July 31st and August 6th can earn a total of 1950+700=2650 diamonds."
  ],
  rok: [
    "1. In-game rewards: Directly mailed through in-game or sent through discord with a giftcode.",
    "*If creators have any questions on the rewards,",
    "they can feel free to check with the mod in charge for further clarification",
    "2. Cash: International wires to bank accounts",
    "(i.e. Contracted signed in advance for legitimacy)",
    "Monthly earnings may differ from case to case. To get more information or clarification, kindly reach out to the relevant community manager.",
  ],
  igame: {
    en: [
      "Salary explained",
      "1. In-game rewards: Directly mailed through in-game",
      "*If creators have any questions on the rewards, they can feel free to check with the mod in charge for further clarification",
      "2. Cash: International wires to bank accounts (i.e. Contracted signed in advance for legitimacy)"
    ],
    id: [
      "Penjelasan Upah",
      "1. Hadiah dalam game: Dikirim langsung dalam game",
      "*Jika kreator memiliki pertanyaan tentang hadiah, mereka dapat menghubungi moderator yang bertanggung jawab untuk mendapatkan penjelasan lebih lanjut",
      "2. Tunai: Transfer internasional ke rekening bank (yaitu, kontrak yang ditandatangani terlebih dahulu untuk keabsahan)"
    ],
    th: [
      "คำอธิบายเงินเดือน",
      "1. รางวัลในเกม: ส่งให้ทางจดหมายในเกมโดยตรง",
      "*ถ้าครีเอเตอร์มีคำถามใดๆ เกี่ยวกับรางวัล ครีเอเตอร์สามารถตรวจสอบกับม็อดที่รับผิดชอบเพื่อขอคำชี้แจงเพิ่มเติมได้",
      "2. เงินสด: โอนผ่านบัญชีธนาคารระหว่างประเทศ (เช่น เซ็นสัญญาล่วงหน้าเพื่อความถูกต้องตามกฎหมาย)",
    ],
    vi: [
      "Giải Thích về Thù Lao",
      "1. Phần thưởng trong trò chơi: Gửi trực tiếp qua thư trong trò chơi",
      "*Nếu nhà sáng tạo có bất kỳ thắc mắc nào về phần thưởng, vui lòng liên hệ với quản trị viên phụ trách để được giải thích thêm",
      "2. Tiền mặt: Chuyển khoản quốc tế tới tài khoản ngân hàng (tức là Hợp đồng ký trước để hợp pháp hóa)"
    ],
    jp: [
      "給料について",
      "1. ゲーム内報酬：ゲーム内で直接送られます。",
      "※報酬について何か疑問がある場合は、お気軽に担当モデレーターにお問い合わせください",
      "2. 現金：銀行口座への国際送金（すなわち、正当性を確保するために事前に契約を締結）",
    ],
    sk: [
      "급여 설명",
      "1. 게임 내 보상: 게임 내 우편",
      "*크리에이터는 보상과 관련하여 궁금한 사항이 있으면 언제든지 운영팀에 문의하여 확인할 수 있습니다.",
      "2. 현금: 은행 계좌로 이체(합법성을 위해 먼저 계약을 체결합니다)"
    ],
    "zh-tw": [
      "薪資解釋",
      "1. 遊戲內獎勵：直接通過遊戲內郵寄",
      "*如果創作者對獎勵有任何疑問，可以隨時向負責的Mod查詢以獲得進一步的解釋",
      "2. 現金：匯款到銀行帳戶（即事先簽署合約以確保合法性）",
    ]
  }
};

// 注册页额外申请链接
// const ExtraApplyEn = () => {
//   const { globaLang } = useContext(rootContext);
//   useEffect(() => {}, [globaLang]);
//   return (
//     <p>
//       {signData().signOtherText1} <br />
//       {signData().signOtherText2} <a target="_blank" rel="noreferrer" onClick={() => { window.gtag("event", "en_extra_sign"); }} href="https://forms.gle/ePxxm2g2RWvmtYUb6">{signData().signOtherLink}</a>
//     </p>
//   );
// };

export const defaultRegionData = {
  explainedList,
  tierExplainedList,
  discordLink,
  gameIcons,
  earningTip,
  gameTag
};

export const regionStoreEn = {
  // extraApplyLink: <ExtraApplyEn />,
  // extraSingUpRule: [<span key="exRule1Eng">An English video content creator on any of the following platforms (Youtube, Twitch, Tiktok)</span>],
  region: "en"
};